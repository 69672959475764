<template>
  <Loader class="mt-6 flex justify-center" v-if="showLoader" />
  <div v-else>
    <div class="flex justify-start items-end">
      <div>
        <HeaderGlobal
          titlemenu1="Friends’ Pets"
          :to1="'/petoverview/new'"
          :titlemenu2="$t('pet_dropdown_menu_public_profile')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="w-full font-proximaMedium">
        <h3 class="mb-5 text-2xl uppercase">{{ biometricData.callName }}</h3>
        <div class="w-full flex items-start justify-evenly">
          <div
            class="relative cursor-pointer"
            @click="profilePictureModals = true"
          >
            <img
              class="mt-6 border border-primary rounded-full"
              style="width: 102px; height: 102px"
              :src="picture"
              alt="pet_icon"
            />
            <!-- ownerData.profileImage -->
            <!-- v-if="ownerData" -->
            <img
              v-if="ownerData"
              class="w-12 h-12 border border-white rounded-full absolute top-2 -right-3"
              :src="ownerData.profileImage"
              alt="pp-lg"
            />
            <!--  <img v-else class="
                w-12
                h-12
                border border-white
                rounded-full
                absolute
                top-2
                -right-3
              " src="@/assets/images/pp-lg.png" alt="pp-lg" />  -->
          </div>
          <ul class="text-left font-proximaLight list-disc">
            <li v-if="ownerData" class="my-2 text-primary">
              <span class="text-black">{{
                ownerData.firstName + " " + ownerData.lastName
              }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.age }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.breeding_sex }}</span>
            </li>
            <li v-if="biometricData.height_weight" class="my-2 text-primary">
              <span class="text-black">{{ biometricData.height_weight }}</span>
            </li>
            <!-- <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.blood }}</span>
            </li> -->
          </ul>
        </div>
        <div>
          <ul class="mt-4 flex flex-wrap">
            <!--  <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="goToCareBlog"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careblog-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Diary</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="goToMedicalHistory"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/medicalhistory-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Medical History</span>
            </li> -->
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/emergencycontact-icon.png"
                alt="emergencycontact-icon"
              />
              <span>Emergency Contacts</span>
            </li>
            <li
              v-if="CareRoutineData && CareRoutineData.length > 0"
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              v-on:click="goToCareRoutine"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careroutine-icon.png"
                alt="careroutine-icon"
              />
              <span>{{ $t("pet_dropdown_menu_care_routine") }}</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="favoriteServiceModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/favoriteservices-icon.png"
                alt="favoriteservices-icon"
              />
              <span>Favorites Services</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="noteModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/notes-icon.png"
                alt="notes-icon"
              />
              <span>Notes</span>
            </li>
          </ul>
        </div>
        <!--  <div>
          <ul class="mt-4 flex flex-wrap">
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careblog-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Diary</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/medicalhistory-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Medical History</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              v-on:click="goToCareRoutine"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careroutine-icon.png"
                alt="careroutine-icon"
              />
              <span>{{ $t("pet_dropdown_menu_care_routine") }}</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="favoriteServiceModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/favoriteservices-icon.png"
                alt="favoriteservices-icon"
              />
              <span>Favorites Services</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="noteModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/notes-icon.png"
                alt="notes-icon"
              />
              <span>Notes</span>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <!-- favorite services -->
    <ModalsConfirm :show="favoriteServiceModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="favoriteServiceModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <h3 class="mb-4 text-2xl font-bold font-proximaMedium">
            Favorite Services
          </h3>
          <div class="h-52 overflow-y-auto">
            <div
              class="mb-4 text-left flex items-center"
              v-for="(data, i) in favouriteServices"
              :key="i"
            >
              <img
                class="h-10 w-10 rounded-full"
                :src="require('@/assets/images/pp-3.png')"
                alt="img"
              />
              <div class="pl-2">
                <h5 class="text-primary text-sm font-proximaMedium">
                  {{ data.favouriteServiceEmailAddress }}
                </h5>
                <!-- <span>{{ data.address }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- favorite services end -->
    <!-- note services -->
    <ModalsConfirm :show="noteModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="noteModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <h3 class="mb-4 text-2xl font-bold font-proximaMedium">Owner Note</h3>
          <div class="h-52 overflow-y-auto">
            <div
              class="mb-4 text-left flex items-center"
              v-for="(data, i) in noteData"
              :key="i"
            >
              <div class="pl-2">
                <h5 class="text-primary text-sm font-proximaMedium">
                  {{ data.title }}
                </h5>
                <span>{{ data.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- note services end -->
    <!-- emergency contact -->
    <ModalsConfirm :show="emergencyContactModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="emergencyContactModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <h3 class="mb-4 text-2xl font-bold font-proximaMedium">
            Emergency Contacts
          </h3>
          <div class="h-52 overflow-y-auto">
            <div
              class="mb-4 text-left flex items-center"
              v-for="(data, i) in emergencyContacts"
              :key="i"
            >
              <img
                v-if="data.profile_picture"
                class="h-10 w-10 rounded-full"
                :src="data.profile_picture"
                :alt="data.name"
              />
              <img
                v-else
                class="h-10 w-10"
                :src="require('@/assets/images/pp-3.png')"
                :alt="data.name"
              />
              <div class="pl-2">
                <h5 class="text-xl font-proximaNormal">
                  {{ data.name || data.contactEmailAddress }}
                  {{ data.contactphoneNo ? "(" : "" }}
                  <a :href="'tel:' + data.contactphoneNo">{{
                    data.contactphoneNo
                  }}</a>
                  {{ data.contactphoneNo ? ")" : "" }}
                </h5>
                <!-- <span>{{ data.address }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- emergency contact end -->
    <!-- profile picture -->
    <!-- <ModalsConfirm :show="profilePictureModals" v-if="imageArray.length > 0">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="profilePictureModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <vue-image-slider
            class="mx-auto hidden md:block"
            :images="imageArray"
            :intervalVal="3000"
            :height="300"
            :width="400"
          />
          <vue-image-slider
            class="mx-auto block md:hidden"
            :images="imageArray"
            :intervalVal="3000"
            :height="160"
            :width="250"
          />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm> -->
    <!-- profile picture end -->
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import { petRecordById } from "../../../services/petRecordService";
import VueImageSlider from "vue-image-slider";
import { mapActions, mapGetters } from "vuex";
import Loader from "../../loader/Loader.vue";
import { pictureBySpecies } from "../../../methods/petMethods";
import { getGallery } from "../../../services/petRecordService";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    HeaderGlobal,
    ModalsConfirm,
    VueImageSlider,
    Loader,
  },
  data() {
    return {
      petData: {},
      showLoader: true,
      favoriteServiceModals: false,
      noteModals: false,
      emergencyContactModals: false,
      profilePictureModals: false,
      picture: null,
      imageArray: [],
      favoriteData: [
        {
          image: "pp-lg",
          name: "Hundesalon Bad Belzig",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "pp-lg",
          name: "Tierarztpraxis Moon",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "pp-lg",
          name: "Hundesalon Bad Belzig",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "pp-lg",
          name: "Hundesalon Bad Belzig",
          address: "45 Chatswood , Autraili-03470000000",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      petProfileData: "petRecord/getPetProfile",
      emergencyContacts: "contact/getEmergencyContacts",
      favouriteServices: "contact/getFavouriteServices",
    }),

    biometricData() {
      console.log("111", this.petProfileData);
      if (this.petProfileData) {
        return this.petProfileData["Biometric Data"];
      }
    },
    CareRoutineData() {
      console.log("111", this.petProfileData);
      if (this.petProfileData) {
        return this.petProfileData["Care Routine"];
      }
    },

    ownerData() {
      if (this.petProfileData) return this.petProfileData["owner"];
    },

    noteData() {
      return this.petProfileData.care_blog
        ? this.petProfileData.care_blog.own_entries
        : [];
    },

    /*  titlemenu2() {
      return this.petProfileData
        ? 
          " Record " + this.petProfileData["Biometric Data"].callName
        : "";
    },  */
  },
  watch: {
    biometricData: function () {
      this.showLoader = false;
    },
  },

  async created() {
    console.log("//////////////////");
    var retrievedObject = localStorage.getItem("pet");
    this.petData = JSON.parse(retrievedObject);

    this.titlemenu2 =
      nameOfPet(this.petData.species) + " Record: " + this.petData.callName;

    const { data: petGallery } = await getGallery(this.petData.petID);
    petGallery.map((item) => this.imageArray.push(item.petImage));

    this.picture = this.petData.profile_picture
      ? this.petData.profile_picture
      : pictureBySpecies(this.petData);

    this.getEmergencyContacts(localStorage.getItem("email"));
    this.getFavouriteServices(localStorage.getItem("email"));
    this.petData ? this.getPetProfile(this.petData.petID) : "";
  },

  methods: {
    ...mapActions({
      getPetProfile: "petRecord/getPetProfile",
      getEmergencyContacts: "contact/getEmergencyContacts",
      getFavouriteServices: "contact/getFavouriteServices",
    }),

    async goToCareRoutine() {
      //await petRecordById(this.petData.petID).then((result) => {
      this.$router.push({
        path: "/routineoverview/public/" + this.petData.petID,
      });
      /*   this.$router.push({
        name: "RoutineOverviewPet",
        params: { data: this.petData },
      }); */
      //});
    },
    async goToMedicalHistory() {
      //await petRecordById(this.petData.petID).then((result) => {

      this.$router.push({
        name: "MedicalHistoryDashboard",
        params: { data: this.petData },
      });

      /*   this.$router.push({
        name: "RoutineOverviewPet",
        params: { data: this.petData },
      }); */
      //});
    },
    async goToCareBlog() {
      //await petRecordById(this.petData.petID).then((result) => {
      this.$router.push({
        path: "/careblog",
      });
    },
  },
};
</script>

<style scoped></style>
